import request from '@/utils/request'

// 获取配置
export function getSite(data) {
  return request({
    url: '/api/index/getSite',
    method: 'post',
    data,
  })
}
// 会员中心
export function index(data) {
  return request({
    url: '/api/user/index',
    method: 'post',
    data,
  })
}
// 修改会员个人信息
export function profile(data) {
  return request({
    url: '/api/user/profile',
    method: 'post',
    data,
  })
}
// 登录
export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data,
  })
}
// 注册
export function register(data) {
  return request({
    url: '/api/user/register',
    method: 'post',
    data,
  })
}
// 退出登录
export function logout(data) {
  return request({
    url: '/api/user/logout',
    method: 'post',
    data,
  })
}
// 重置密码
export function resetpwd(data) {
  return request({
    url: '/api/user/resetpwd',
    method: 'post',
    data,
  })
}
// 公告列表
export function noticeList(data) {
  return request({
    url: '/api/index/noticeList',
    method: 'post',
    data,
  })
}
// 公告详情页
export function notice(data) {
  return request({
    url: '/api/index/notice',
    method: 'post',
    data,
  })
}
// 余额明细
export function moneyLog(data) {
  return request({
    url: '/api/user/moneyLog',
    method: 'post',
    data,
  })
}
// 佣金转余额
export function transfer(data) {
  return request({
    url: '/api/user/transfer',
    method: 'post',
    data,
  })
}
// 充值余额
export function recharge(data) {
  return request({
    url: '/api/user/recharge',
    method: 'post',
    data,
  })
}
// 充值记录
export function rechargeLog(data) {
  return request({
    url: '/api/user/rechargeLog',
    method: 'post',
    data,
  })
}
// 提现申请
export function withdraw(data) {
  return request({
    url: '/api/user/withdraw',
    method: 'post',
    data,
  })
}
// 取消提现
export function withdrawCancel(data) {
  return request({
    url: '/api/user/withdrawCancel',
    method: 'post',
    data,
  })
}
// 提现记录
export function withdrawLog(data) {
  return request({
    url: '/api/user/withdrawLog',
    method: 'post',
    data,
  })
}
// 发起查询
export function query(data) {
  return request({
    url: '/api/user/query',
    method: 'post',
    data,
  })
}
// 查询日志
export function orderList(data) {
  return request({
    url: '/api/user/orderList',
    method: 'post',
    data,
  })
}
// 订单详情/
export function orderInfo(data) {
  return request({
    url: '/api/index/orderInfo',
    method: 'post',
    data,
  })
}
// 确认查征信
export function confirm(data) {
  return request({
    url: '/api/index/confirm',
    method: 'post',
    data,
  })
}
// 微信授权获取openid
export function getOpenid(data) {
  return request({
    url: '/api/index/getOpenid',
    method: 'post',
    data,
  })
}
// 获取充值订单支付参数
export function pay(data) {
  return request({
    url: '/api/index/pay',
    method: 'post',
    data,
  })
}