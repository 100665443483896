var jweixin = require('jweixin-module')

export default {
  wxPay: function (payinfo, callback) {
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      {
        appId: payinfo.appId.toString(), //公众号名称，由商户传入
        timeStamp: payinfo.timeStamp.toString(), //时间戳
        nonceStr: payinfo.nonceStr.toString(), //随机串
        package: payinfo.package.toString(),
        signType: payinfo.signType.toString(), //微信签名方式：
        paySign: payinfo.paySign.toString(), //微信签名
      },
      function (ress) {
        callback && callback(ress)
      }
    )
  },
  verify() {},
}
