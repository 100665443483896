import Vue from 'vue'
import Vuex from 'vuex'

import {
  getSite,
  index
} from "@/api/index";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: null,
    userInfo: null,
    siteInfo: null
  },
  mutations: {
    // token
    SET_TOKEN: (state, token) => {
      state.token = token
      window.localStorage.setItem('token', token)
    },
    REMOVE_TOKEN: (state) => {
      state.token = null
      window.localStorage.removeItem('token')
    },

    // userInfo
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
      window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    REMOVE_USERINFO: state => {
      state.userInfo = null;
      window.localStorage.removeItem("userInfo");
    },

    // siteInfo
    SET_SITEINFO: (state, siteInfo) => {
      state.siteInfo = siteInfo;
      window.localStorage.setItem("siteInfo", JSON.stringify(siteInfo));
    },
  },
  getters: {},
  actions: {
		// 会员中心
		getIndexFn({commit}) {
			return new Promise((resolve, reject) => {
				index().then(res => {
          commit("SET_USERINFO", res.data)
					resolve(res.data)
				}).catch(e => {
					reject()
				});
			})
		},

    // 获取全局配置
		getSiteFn({commit}) {
			return new Promise((resolve, reject) => {
				getSite().then(res => {
          commit("SET_SITEINFO", res.data)
					resolve(res.data)
				}).catch(e => {
					reject()
				});
			})
		},

    // 退出登录
    outLoginClick({ commit }) {
      commit('REMOVE_TOKEN')
      commit('REMOVE_USERINFO')
    },
    
    // 如有缓存直接赋值vuex 不去请求接口
    loadLocal({ commit }) {
      const token = window.localStorage.getItem('token')
      if (token && token != 'undefined') {
        commit('SET_TOKEN', token)
      }
      const userInfo = window.localStorage.getItem('userInfo')
      if (userInfo && userInfo != 'undefined') {
        commit('SET_USERINFO', JSON.parse(userInfo))
      }
      const siteInfo = window.localStorage.getItem('siteInfo')
      if (siteInfo && siteInfo != 'undefined') {
        commit('SET_SITEINFO', JSON.parse(siteInfo))
      }
    },
  },
})

export default store
